footer {
  background-color: #111111;
  color: #b5b3b3;
  padding: 40px 0;

  .footer_container {
    text-align: center;

    .social-icon {
      margin: 0 5px;
    }
  }
}
