.contacts_container {
  position: relative;
  background-color: black;

  h5 {
    font-size: 25px;
    margin: 15px 40px;
    font-family: "PT Serif", serif;
  }

  .contacts_image {
    width: 100%;
    height: 800px;
    object-fit: cover;
    filter: brightness(30%);
  }
  .contacts_box {
    position: absolute;
    width: 500px;
    font-size: 20px;
    border-left: 1px solid rgba(255, 255, 0, 0.5);
    background: rgba(0, 0, 0, 0.7);
    line-height: 1.7;
    margin-bottom: 20px;
    top: 50%;
    right: 50vmin;
    -ms-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    border-radius: 0 10px 10px 0;

    form {
      display: flex;
      flex-direction: column;
      padding: 0 40px 40px 40px;
      border-radius: 10px;

      label {
        padding-top: 10px;
        color: #b5b3b3;
      }

      .contacts_input {
        background-color: rgb(60, 60, 60);
        border-radius: 10px;
        color: aliceblue;
      }

      .button {
        background-color: #44f;
        border-radius: 20px;
        margin-top: 20px;
        color: aliceblue;
      }

      .captcha {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .contacts_box {
    width: 80% !important;
    right: 50% !important;
    margin: 0 auto;
  }
}
