.about_container {
  position: relative;
  background-color: black;

  .about_image {
    filter: brightness(30%);
    height: 600px;
    width: 100%;
    object-fit: cover;
  }

  .about_box {
    width: 50%;
    font-size: 20px;
    border-left: 1px solid rgba(255, 255, 0, 0.5);
    background: rgba(0, 0, 0, 0.7);
    line-height: 1.7;
    margin-bottom: 20px;
    position: absolute;
    top: 50%;
    left: 10;
    -ms-transform: translate(10%, -50%);
    transform: translate(10%, -50%);

    h2 {
      margin: 20px 0;
      padding: 0 40px;
    }

    .about_text {
      font-family: "PT Serif", serif;
      padding: 40px;
      padding-top: 0;
    }
  }
}

@media screen and (max-width: 850px) {
  .about_box {
    width: 100% !important;
    left: 0 !important;
    -ms-transform: translate(0%, -50%) !important;
    transform: translate(0%, -50%) !important;
  }
}
