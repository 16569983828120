header {
  position: relative;
  background-color: black;

  .header_image {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    filter: brightness(30%);
  }

  .text_box {
    width: 300px;
    position: absolute;
    font-family: "PT Serif", serif;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .header_text {
      text-align: center;
      margin: 0 !important;
      animation: textAinm 0.5s ease 0.5s;
      animation-fill-mode: both;
    }

    .header_subtext {
      margin: 0 !important;
      animation: subtextAinm 0.5s ease 0.5s;
      animation-fill-mode: both;
    }
  }

  .social_icons {
    position: absolute !important;
    top: 90%;
    left: 50%;
    -ms-transform: translate(-50%, -20%);
    transform: translate(-50%, -20%);
    animation: socialAnim 1s ease 1s;
    animation-fill-mode: both;

    .social-icon {
      margin: 4px;
    }
  }
}

@keyframes textAinm {
  0% {
    padding-bottom: 20px;
    opacity: 0;
  }

  100% {
    padding-bottom: 0px;
    opacity: 100%;
  }
}

@keyframes subtextAinm {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes socialAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  h1 {
    font-size: 30px !important;
  }
}
