.skills_info {
  background-color: #111111;
  margin: 0 auto;
  font-size: 30px;
  padding-top: 100px;
  padding-bottom: 20px;
  text-align: center;
}

.skills_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #111111;
  font-size: 20px;
  padding-bottom: 50px;

  .skills_card {
    padding: 16px;
    margin: 40px 50px;
    background-color: rgb(60, 60, 60);
    border: 1px solid black;
    border-radius: 40px;
    width: 250px;
    height: 230px;
    -webkit-box-shadow: 0px 0px 30px 7px rgba(153, 153, 153, 1);
    -moz-box-shadow: 0px 0px 30px 7px rgba(153, 153, 153, 1);
    box-shadow: 0px 0px 50px 10px rgb(226, 226, 226);

    h3 {
      text-align: center;
      border-bottom: 2px solid black;
    }
  }
}
