h4 {
  text-align: center;
  background-color: #111111;
  margin: 0 !important;
  padding: 100px 15px 15px 15px;
  font-family: "PT Serif", serif;
}
.box {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 40px;
}

.card-group {
  padding: 20px 0;
  background-color: #111111;

  .card {
    font-family: "PT Serif", serif;
    overflow: hidden;
    background-color: #111111;
    margin: 20px;
    .card-img-top {
      transition: transform 2s ease;
      height: 300px;
      object-fit: cover;
      border: 0;
    }
    .card-img-top:hover {
      transform: scale(1.1);
    }

    a {
      color: aliceblue;
      text-decoration: none;
    }

    .card-body {
      background-color: #111111;
    }
  }
}

@media screen and (max-width: 1300px) {
  .box {
    width: 80%;
  }
}
